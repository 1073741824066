<!-- ///////\\\\\\\
<link rel="stylesheet" [href]="VenueCSS"> -->

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise-fade" [fullScreen] = "true"><p style="color: black" > loading... </p></ngx-spinner>
<div *ngIf="ShowTimeOut" style="background-color: #6B798D; color: white;text-align: center;padding: 10px; font-weight: bold; font-size: 20px;">
    Your sessions is idle, your session will end in {{countdown}} seconds.
  </div>
<p-toast position="top-center"></p-toast>

<router-outlet></router-outlet>
