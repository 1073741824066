<link rel="stylesheet" [href]="VenueCSS">

<div *ngIf="PaymentInProgress" class="PaymentInProgress">
    <span class="MakingPayment">
    <div>Making Payment, Please wait...</div>
    <p-progressSpinner class="ProgressSpinner"></p-progressSpinner>
    </span>
</div>

<div class="padding15px">
    <div class="publicImage"><img src={{publicImageURL}} width={{imageWidth}} height={{imageHeight}}></div>
    <div style="clear:both; width:100%"></div>

    <div *ngIf="!BookingComplete">

        <div style="width: 100%;text-align: center; margin-bottom: 20px;font-weight: bold;">
            Please use the form below to make a payment of {{this.paymentAmount |  currency:'GBP'}}.
        </div>
        <div *ngIf="paymentError != ''" style="color:red; font-weight:bold; width:100%">{{paymentError}}</div>
    
        <form action="/charge" method="post" id="payment-form" style="margin: auto;">
            <div class="form-row">
              <div id="payment-element"></div>
              <div id="payment-errors" role="alert"></div>
              <div id="payment-message" role="alert"></div>
            </div>      
    
            <button *ngIf="showButton" id="paybutton" class="p-button p-element p-button w-auto mt-3" style="width:100% !important;">Pay {{this.paymentAmount |  currency:'GBP'}}</button>
        </form>

        <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;margin-top:20px">
            Version: {{version}}
            <br>
            <a href="https://www.wannabook.online">Powered by WannaBook</a>
            <div style="height:81px;">
              &nbsp;
          </div>
        </div>
    </div>
</div>


<div *ngIf="BookingComplete">
    <div class="p-3 flex flex-column flex-auto">
        <h3 class="BookingSummaryHeader">Booking Summary</h3>
        <div style="margin-top:0px;margin-bottom: 20px">Thankyou for your payment, you will be sent a confirmation of your booking, we look forward to seeing you.</div>
        <div *ngIf="BookingSummary">
            <div class="SummaryEntry">
                <span class="pi pi-user" class="MarginRight10"></span><b>Customer</b> : {{BookingSummary.customerName}}
            </div>
            <div  class="SummaryEntry">
                <span class="pi pi-pound" class="MarginRight10"></span><b>Outstanding</b> : <span class="RedBold">{{BookingSummary.outstanding | currency:'GBP' }}</span>
            </div>
            <div class="SummaryEntry">
                <span class="pi pi-users" class="MarginRight10"></span><b>Admissions</b> : {{BookingSummary.admissions}}
            </div>
            <div class="SummaryEntry">
                <span class="pi pi-calendar" class="MarginRight10"></span><b>Start Date/Time</b> : {{BookingSummary.startTime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}
            </div>
            <div class="SummaryEntry">
                <span class="pi pi-box" class="MarginRight10"></span><b>Package</b> : {{BookingSummary.packageName}}
            </div>
            <div class="SummaryEntry">
                <span class="pi pi-calendar" class="MarginRight10"></span><b>Sales ID</b> : {{BookingSummary.bookingIdHR}}
            </div>

            <div class="SummaryEntry">
                <span class="pi pi-pound" class="MarginRight10"></span><b>Paid</b> : <span>{{BookingSummary.paid | currency:'GBP' }}</span>
            </div>

            <div class="SummaryEntry">
                <span class="pi pi-pound" class="MarginRight10"></span><b>Sales Value</b> : {{BookingSummary.total | currency:'GBP' }}
            </div>

            <div *ngIf="BookingSummary.totaldiscounts > 0" class="SummaryEntry">
                <span class="pi pi-pound" class="MarginRight10"></span><b>Discounts</b> : {{BookingSummary.totaldiscounts | currency:'GBP' }}
            </div>

            <div *ngIf="BookingSummary.totalbookingfees > 0" class="SummaryEntry">
                <span class="pi pi-pound" class="MarginRight10"></span><b>Booking Fee</b> : {{BookingSummary.totalbookingfees | currency:'GBP' }}
            </div>

            <div class="SummaryEntry">
                <span class="pi pi-list" class="MarginRight10"></span><b>Itinerary</b>:

                <div class="Itinerary">
                    <table [cellPadding]="3" class="MobileTable">
                        <tr class="InineraryEntries">
                            <td class="Width5">&nbsp;</td>
                            <td class="Width50">
                                <b>Arrival:</b>
                            </td>
                            <td class="Width20">
                                {{BookingSummary.arrival| date: 'HH:mm' : 'UTC'}}
                            </td >
                            <td class="Width20">&nbsp;</td>
                        </tr>

                        <tr class="InineraryEntries" *ngFor="let itin of (BookingSummary.itineraryEX)">
                            <td>&nbsp;</td>
                            <td>
                               <b>{{itin.resource}}</b>
                            </td>
                            <td>
                                {{itin.starttime}}
                            </td>
                            <td >
                                {{itin.people}}&nbsp;people
                            </td>

                        </tr>

                        <tr class="InineraryEntries">
                            <td>&nbsp;</td>
                            <td>
                                <b>End:</b>
                            </td>
                            <td>
                                {{addMs(BookingSummary.endTime, 1)| date:'HH:mm' : 'UTC'}}
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>
            </div>


            <div *ngIf="BookingSummary.extras != ''" class="SummaryEntry">
                <span class="pi pi-star" class="MarginRight10"></span><b>Extras</b> : {{BookingSummary.extras}}
            </div>

 
        </div>
    </div>

    </div>

    <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
        Version: {{version}}
        <br>
        <a href="https://www.wannabook.online">Powered by WannaBook</a>
        <div style="height:81px;">
          &nbsp;
      </div>
    </div>
</div>