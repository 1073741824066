<div class="container">
  <div class="row">
    <div class="col-md-12" style="margin-top:200px;">
      <img src="assets/images/wannabook.png" width="300px;" class="img-fluid mx-auto d-block" alt="WannaBook Logo">
    </div>
    <div style="text-align: center; width:100%">
        Booking your way!
    </div>
  </div>
</div>
