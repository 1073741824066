export const environment = {
  production: false,
  VenueName: "",
  apitarget: "https://dev-api.wannabook.online/",
  servicestarget: "https://dev-services.wannabook.online",
  //servicestarget: "https://8329-77-100-213-151.ngrok-free.app",
  PaymentAccountNumber: "pk_test_51NpZ7oJle3gYWSXYx6yfnZXLc3E8hyKxzsbFxkGzsYwT7aSPOCLhSx9kDiIcuQLGxsDTfzZOZflysCcv0O3iUAcK00kWAnhU50",
  version: "1.0.117",
  httpInterceptor: {
    allowedList: [`*`],
  },
};

