<link rel="stylesheet" [href]="VenueCSS">

<div *ngIf="!BookingComplete" style="padding:15px;">
    <div style="text-align: center;margin-bottom:25px;"><img src={{publicImageURL}} width="120px"></div>

    <div *ngIf="PaymentInProgress" class="PaymentInProgress">
        <span class="MakingPayment">
        <div>Making Payment, Please wait...</div>
        <p-progressSpinner class="ProgressSpinner"></p-progressSpinner>
        </span>
    </div>

    <div class="p-3 flex flex-column flex-auto">
        <div class="text-900 font-medium text-xl mb-3"><h3>Sell a Gift Voucher
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Sell a gift voucher to a customer.
        </p>
    
        <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    
                    <label style="margin-bottom: 20px;" class="font-medium">Amount</label>
                    <div>
                        <p-toggleButton id="toggle5" [(ngModel)]="toggle5" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px' }" onLabel="£5" offLabel="£5" (onChange)="selectValue(5)"></p-toggleButton>
    
                        <p-toggleButton id="toggle10" [(ngModel)]="toggle10" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px', 'margin-right': '1%'}" onLabel="£10" offLabel="£10" (onChange)="selectValue(10)"></p-toggleButton>
    
                        <p-toggleButton id="toggle20" [(ngModel)]="toggle20" [style]="{'float': 'left', 'margin': '10px 0% 10px 0px', 'width': '32%', 'height': '80px'}" onLabel="£20" offLabel="£20" (onChange)="selectValue(20)"></p-toggleButton>
    
                        <p-toggleButton id="toggle25" [(ngModel)]="toggle25" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px'}" onLabel="£25" offLabel="£25" (onChange)="selectValue(25)"></p-toggleButton>
    
                        <p-toggleButton id="toggle50" [(ngModel)]="toggle50" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px', 'margin-right': '1%'}" onLabel="£50" offLabel="£50" (onChange)="selectValue(50)"></p-toggleButton>
    
                        <p-toggleButton id="toggle100" [(ngModel)]="toggle100" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px'}" onLabel="£100" offLabel="£100" (onChange)="selectValue(100)"></p-toggleButton>
                    
                        <div style="clear:both">&nbsp;</div>
                        <span  style="margin-right:15px; margin-right: 15px; margin-top: 2px !important;float: left;;">Custom Amount</span>
                        <p-inputSwitch id="customAmount" [(ngModel)]="Voucher.customamount" (onChange)="customAmountToggle()"></p-inputSwitch>
    
                        <div *ngIf="Voucher.customamount">
                            <p-inputNumber [(ngModel)]="customAmountValue" [style]="{'margin-top' : '20px'}" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" mode="currency" currency="GBP" locale="en-GB" [min]="0" [max]="9999" (onBlur)="setValue()" (onInput)="setValue()"></p-inputNumber>
                        </div>
                        <div *ngIf="!ValueValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Value must be greater than 0</div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label style="margin-bottom: 20px;" class="font-medium">Customer Information</label>
                    <div>
                        <div class="field mb-4 col-12">
                            <label for="lastname" class="font-medium">Your Name</label>
                            <input id="lastname" type="text" pInputText autocomplete="family-name" [(ngModel)]="Voucher.customername">
                        </div>
                        <div *ngIf="!NameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                        <div style="width:100%">
                            <div class="field mb-4 col-12">
                                <label for="email" class="font-medium">Email</label>
                                <input id="email" type="email" pInputText autocomplete="email" [(ngModel)]="Voucher.emailaddress">
                            </div>
                         
                            <div *ngIf="!EmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                            <div *ngIf="!EmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="voucherForSomeoneElse" class="font-medium">Is this voucher is for someone else?</label>
                            <p-selectButton id="voucherForSomeoneElse" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Voucher.alternativerecipient"></p-selectButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div *ngIf="Voucher.alternativerecipient" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label style="margin-bottom: 20px;" class="font-medium">Recipient Information</label>
                    <div>
                        <div class="field mb-4 col-12">
                            <label for="lastname" class="font-medium">Recipient's Name</label>
                            <input id="lastname" type="text" pInputText autocomplete="family-name" [(ngModel)]="Voucher.recipientsname">
                        </div>
                        <div *ngIf="!RecipientNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                        <div style="width:100%">
                            <div class="field mb-4 col-12">
                                <label for="email" class="font-medium">Recipient's Email</label>
                                <input id="email" type="email" pInputText autocomplete="email" [(ngModel)]="Voucher.recipientsemailsaddress">
                            </div>
                         
                            <div *ngIf="!RecipientEmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                            <div *ngIf="!RecipientEmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                        </div>
    
                        <div class="field mb-4 col-12">
                            <label for="venueclosingstartdate">Message</label>
                            <textarea pInputText type="text" style="width:100%; height: 100px;" [(ngModel)]="Voucher.message"></textarea>
                        </div>
                        <div *ngIf="!messageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a message.</div>
    
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="Voucher.initialvalue > 0 || Voucher.customamount" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
            
            <button *ngIf="!ShowPayment" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button" (click)="makePayment()">
                <span class="pi pi-credit-card" style="margin-right:8px;"></span>
                <span>Make payment</span>
            </button>
    
            <div *ngIf="ShowPayment" class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label style="margin-bottom: 20px;" class="font-medium">Payment</label>
                    <div>
                        <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">
                            <div class="grid formgrid p-fluid">
                                <div *ngIf="totalValueOfBooking != 0" class="ValueOfBooking">
                                    Value of Booking: {{this.Voucher.initialvalue |  currency:'GBP'}} 
                                </div>
         
                                <div class="ClearBothWidth100">
                            </div>

                            <div style="clear:both; width:100%"></div>
        
                            <div *ngIf="paymentError != ''" style="color:red; font-weight:bold; width:100%">{{paymentError}}</div>
        
                            <form action="/charge" method="post" id="payment-form">
                                <div class="form-row">
                                  <div id="payment-element"></div>
                                  <div id="payment-errors" role="alert"></div>
                                  <div id="payment-message" role="alert"></div>
                                </div>
        
                                <button class="p-button p-element p-button w-auto mt-3" style="width:100% !important;">Pay {{this.Voucher.initialvalue |  currency:'GBP'}}</button>
                              </form>
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    
    <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
        Version: {{version}}
        <br>
        <a href="https://www.wannabook.online">Powered by WannaBook</a>
        <div style="height:81px;">
          &nbsp;
      </div>
    </div>
    
</div>

<div *ngIf="BookingComplete">
    <div class="p-3 flex flex-column flex-auto">
        <h3 class="BookingSummaryHeader">{{VoucherConfirmationSummary}}</h3>
        <div style="margin-top:0px;margin-bottom: 20px">{{VoucherConfirmationText}}</div>

        <div class="p-3 flex flex-column flex-auto">
            <h3 style="margin-bottom:20px;" i18n>Voucher Summary</h3>
    
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-ticket" style="margin-right: 10px;"></span><b>Voucher Code</b> : {{Voucher.vouchercode}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-th-large" style="margin-right: 10px;"></span><b>Voucher PIN</b> : {{Voucher.voucherpin}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-user" style="margin-right: 10px;"></span><b>Customer Name</b> : {{Voucher.customername }}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Voucher Value</b> : {{Voucher.initialvalue | currency:CurrencySymbol }}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Expiry Date</b> : {{Voucher.expiry | date:'dd/MM/yyyy' : 'UTC'}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-user" style="margin-right: 10px;"></span><b>Recipient</b> : {{Voucher.recipientsname}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-envelope" style="margin-right: 10px;"></span><b>Recipient Email</b> : {{Voucher.recipientsemailsaddress}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-heart-fill" style="margin-right: 10px;"></span><b>Message</b> : {{Voucher.message}}
            </div>
    
        </div>


        <div>
            <div class="StartAgain">
                <button pButton pRipple type="button" class="p-button p-element p-button w-auto mt-3" class="Important100" (click)="startAgain()">
                <span class="pi pi-calendar-plus" class="MarginRight8"></span>
                Return to Website</button>
            </div>
        </div>
    </div>

    <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
        Version: {{version}}
        <br>
        <a href="https://www.wannabook.online">Powered by WannaBook</a>
        <div style="height:81px;">
          &nbsp;
      </div>
    </div>
</div>