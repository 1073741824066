import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { event_returndata, event_returndataChartData } from 'src/types/event_returndata';
import { bmxurl } from 'src/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { cEvent, datasets } from 'src/types/event';
import { ConfirmationService, MessageService } from 'primeng/api';
import { admission } from 'src/types/admission';
import { AvailabilityReq, AvailableTimes } from 'src/PackageClasses';
import { cResource, resource } from 'src/types/resource';
import { v4 as uuidv4  } from 'uuid';
import { cPackage } from 'src/types/package';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { Buffer } from "buffer";
import { host_returndata } from 'src/types/host_returndata';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit {
  ListEvents: any = [];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  Event: any;
  DisabledEvent: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  EventObj: cEvent;
  httpResponse: any;
  id: string;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  basicOptions: any;
  full: boolean = false;
  cancelled: boolean = false;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  showEmailCustomers: boolean = false;
  EmailText: string = "";
  EmailSubject: string = "";
  SelectedEvent: any;
  SelectedEventDate: any;

  ScreenLayout: any[] = [];

  subjectValid: boolean = true;
  textValid:  boolean = true;
  textValidDT:  boolean = true;

  screenMemoryEvents: any[] = [];
  screenMemoryBookingss: any[] = [];
  SelectedEmailTemplate: any;
  ListEmailTemplates: any[] = [];
  ExampleEmail: string = "";
  EmailContainsDynamicText: boolean = false;
  DynamicContentButton: string = "";

  ShowDynamicTextEditor: boolean = false;
  dynamicTextArray: any[] = [];
  MasterCopy: string= "";
  ListEventDateFilter: any[] = [];
  SelectedEventDateFilter: any = "ALL";
  AvailabilityReq = {} as AvailabilityReq;
  startTimes: any;
  startTimesFiltered: any;
  SelectedAdmissions: any[] = [];
  customerSelectedResources: any[] = [];
  SelectedPackage: any;
  SelectedDate: Date;
  SelectedSeletableResource: any[] = [];
  SelectedSeletableResourceBool: boolean[] = [];  
  SelectedResources: any[] = [];
  includesComplexResources: boolean = false;
  includesOptionalResources: boolean = false;

  systemChoosesAllResources: boolean = true;
  doneAvailability: boolean = false;
  
  publicImageURL: string = "";
  imageWidth: number = 150;
  imageHeight: number = 150;
  defaultColour: string="";

  selectedDateTime: boolean[] = [];

  venueName: string = "";
  VenueCSS: any;

  MinAdmissions: number = 0;
  minNumberOfPeopleMet: boolean = true;

  version: string = environment.version;

  EventIdArray: any[] = [];
  MustIncludePass: boolean = true;

  totalAdmissions: number;

  totalAdmissionInRange: boolean = true;

  totalAdmissionsPrice: number = 0;

  CurrentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0);

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService,private confirmationService: ConfirmationService, private route: ActivatedRoute,private sanitizer: DomSanitizer) {

    }

    sumAdmissions(){
      this.totalAdmissions = this.SelectedAdmissions.map(a => a.noofpeople).reduce(function(a, b)
      {
        return Number(a) + Number(b);
      });
  
      if((this.totalAdmissions > this.SelectedPackage.max && this.SelectedPackage.max > 0) || (this.totalAdmissions < this.SelectedPackage.min && this.SelectedPackage.min > 0))
      {
        this.totalAdmissionInRange = false;
      }
      else
      {
        this.totalAdmissionInRange = true;
      }
  
      this.totalAdmissionsPrice = 0;
  
      this.SelectedAdmissions.forEach(adm => {  
        this.totalAdmissionsPrice = this.totalAdmissionsPrice + Number(adm.price) * Number(adm.noofpeople);
      });
  
    }

    
  openBookings(inEvent, inEventadHocDate){

    inEventadHocDate.showEventDateBookings = !inEventadHocDate.showEventDateBookings;

    let found = false;
    let index = 0;

    if(this.screenMemoryBookingss == null)
    {
      this.screenMemoryBookingss = [];
    }

    if(this.screenMemoryBookingss != null)
    {
      this.screenMemoryBookingss.forEach(element => {
        if(element == inEventadHocDate.id)
        {
          found = true;
          this.screenMemoryBookingss.splice(index, 1);
        }
        index++;
      });

      if(!found)
      {
        this.screenMemoryBookingss.push(inEventadHocDate.id);
      }
      
      localStorage.setItem("screenMemoryBookingss", JSON.stringify(this.screenMemoryBookingss));
    }

    if(inEventadHocDate.showEventDateBookings)
    {
      let epoc: number = (new Date(inEventadHocDate.startdatetime).getTime()/1000);
      this.http.get(bmxurl + "eventbookings/" + this.VenueID + "/" + inEvent.eventid + "/" + epoc).subscribe(response => {
        if((response as event_returndata).succeeded == true)
        {
          inEventadHocDate.eventDateBookings = (response as event_returndata).data;
        }
      });
    }
  }

  openEvent(inEvent) {
    inEvent.showinnerbookings = !inEvent.showinnerbookings;

    let found = false;
    let index = 0;

    if(this.screenMemoryEvents == null)
    {
      this.screenMemoryEvents = [];
    }

    if(this.screenMemoryEvents != null)
    {
      this.screenMemoryEvents.forEach(element => {
        if(element == inEvent.eventid)
        {
          found = true;
          this.screenMemoryEvents.splice(index, 1);
        }
        index++;
      });

      if(!found)
      {
        this.screenMemoryEvents.push(inEvent.eventid);
      }
      
      localStorage.setItem("screenMemoryEvents", JSON.stringify(this.screenMemoryEvents));
    }
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      let param = params['venue'];

      this.http.get(bmxurl + "/host/" + param).subscribe(response => {
        
        this.httpResponse =  (response as host_returndata);

        if((response as host_returndata).succeeded)
        {
          if(this.httpResponse.data != null)
          {
            this.venueName = this.httpResponse.data.venuename;
            localStorage.setItem("SmartVenueName", param);
            this.VenueID = this.httpResponse.data.venueid;
            localStorage.setItem("SmartVenueID", this.VenueID);

            let tempCSS = bmxurl + "/css/" + this.VenueID;
            this.VenueCSS = this.sanitizer.bypassSecurityTrustResourceUrl(tempCSS);

            localStorage.removeItem("EventBookingPassed");
            localStorage.removeItem("EventBookingSelectedTime");
            localStorage.removeItem("EventBookingSelectedDate");
            localStorage.removeItem("EventBookingSelectedPackage");
            localStorage.removeItem("EventBookingSelectedAdmissions");
            localStorage.removeItem("EventBookingCustomerSelectedResources");
      
            this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
              this.httpResponse =  (response as venuesettings_returndata)
              
              if(this.httpResponse.succeeded)
              {
                //Set Venue Settings ID
                localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
            
                this.httpResponse.data.venueSettings.forEach(element => {
                  localStorage.setItem(element.name, element.value);
        
                  if(element.name == "PublicImgURL")
                    this.publicImageURL = element.value;
                  if(element.name == "PublicImageheight")
                    this.imageHeight = element.value;
                  if(element.name == "PublicImageWidth")
                    this.imageWidth = element.value;
                  if(element.name == "DefaultColour")
                    this.defaultColour = element.value;
      
                });

                this.loadgrid(false);
        
              }
              else{
            
              }
            }, error => {
            
            });
          }
          else
          {
            this.VenueID = "";
          }

        }

      }, error => {
        console.log(error);
      })
    });
  }

  CheckAvailability(e)
  {
    this.SelectedAdmissions = e.admissions;

    this.getPackageResources(e.packageid, e);
  }

  checkDates(date1, date2)
  {
    let date1String = date1.toString().substring(0, 10);
    let date2String = date2.toString().substring(0, 10);

    if(date1String == date2String)
    {
      return true;
    }
    else
    {
      return false;
    }

  }

  loadgrid(showdisabled: boolean)
  {
    this.screenMemoryEvents = JSON.parse(localStorage.getItem("screenMemoryEvents")!);
    this.screenMemoryBookingss = JSON.parse(localStorage.getItem("screenMemoryBookingss")!);

    this.http.get(bmxurl + "eventlist/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      if((response as event_returndata).succeeded == true)
      {
        this.ListEvents = (response as event_returndata).data;

        this.ListEvents.forEach(element => {
          this.EventIdArray.push(element.id);
        });

      this.ListEvents = this.ListEvents.filter(e => e.visibility == "PUBLIC" || e.visibility == "BOTH");

        let newEventDates: any[] = [];

        let index = 0;
        this.ListEvents.forEach(le => {

          newEventDates = [];

          le.eventdates.forEach(ed => {
            var NewEventDate = {
              id: ed.id,
              startdatetime: ed.startdatetime,
              enddatetime: ed.enddatetime,
              disabled: ed.disabled,
              nooftickets: ed.nooftickets,
              noofticketssold: ed.noofticketssold,
              eventtickettypes: ed.eventtickettypes,
              cancelled: ed.cancelled,
              cancelledsubject: ed.cancelledsubject,
              cancelledcontent: ed.cancelledcontent,
              index: index
            }

            let startOfToday = new Date();  

            if(new Date(NewEventDate.startdatetime) > new Date(startOfToday))
            {
              //check if the date is already in the list
              let found = false;
              newEventDates.forEach(element => {
                //just get date without time
                var dateInList = new Date(new Date(element.startdatetime.toString().substring(0, 10))).getTime();
                var dateToBeAdded = new Date(new Date(NewEventDate.startdatetime.toString().substring(0, 10))).getTime();

                if(dateInList == dateToBeAdded)
                {
                  found = true;
                }
              });
              if(!found)
              {
                newEventDates.push(NewEventDate);
                index++;
              }
              
            }
          });

          le.eventdates = newEventDates;

          this.doLinkedAdmissions(le);

          le.admissions.forEach(adm => {

          adm.noofpeople = adm.min;
          });
        });


        if ((response as event_returndata).messages.length > 0)
        {
          (response as event_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
    }, error => {
    });
  }

  toggleMore(inDiv)
  {
    this.startTimesFiltered = [];
    this.startTimes = [];

    this.EventIdArray.forEach(element => {
      let collapsedDivAll = document.getElementById("dv" + element + "collapse") as HTMLElement;
      let toggleButtonAll = document.getElementById("dv" + element + "toggleButton") as HTMLButtonElement;

      if(collapsedDivAll != null)
      {
        collapsedDivAll.hidden = true
        collapsedDivAll.style.display = "true";
      } 

      if(toggleButtonAll != null)
      {
        toggleButtonAll.hidden = false;
      }
    });

    this.ListEvents.forEach(element => {
      element.eventdates.forEach(element => {
        this.selectedDateTime[element.index] = false;
      });
    });

    let collapsedDiv = document.getElementById(inDiv + "collapse") as HTMLElement;
    let toggleButton = document.getElementById(inDiv + "toggleButton") as HTMLButtonElement;

    if(collapsedDiv.hidden == true)
    {
      collapsedDiv.hidden = false
      collapsedDiv.style.display = "false";
    }
    else
    {
      collapsedDiv.hidden = true
      collapsedDiv.style.display = "true";
    }

    toggleButton.hidden = true;


  }

  selectDate(ed, e)
  {
    e.eventdates.forEach(element => {
      if(ed.index != element.index)
      {
        this.selectedDateTime[element.index] = false;
      }
      else
      {
        this.selectedDateTime[element.index] = true;
      }
    });

    this.doneAvailability = false;
    this.SelectedDate = new Date(ed.startdatetime);

    let HasMustIncludes = false;
    let HasSelectedMustIncludes = false;
 
    e.admissions.forEach(element => {
        if(element.mustinclude)
        {
         HasMustIncludes = true;
        }
        if(element.mustinclude && element.noofpeople > 0)
        {
         HasSelectedMustIncludes = true;
        }
    });
 
    if(HasMustIncludes && !HasSelectedMustIncludes)
     {
       this.MustIncludePass = false;
       this.messageService.add({severity:'warn', summary:'Must include', detail:'You must include at least 1 of the admissions with an asterix'});
     }
     else
     {
       this.MustIncludePass = true;
     }

     if(this.MustIncludePass)
          this.CheckAvailability(e);
  }

  doLinkedAdmissions(e)
  {
    let totalNumberOfLinked = 0;

    e.admissions.forEach(adm => {

      if(adm.linkedadmissions != null)
      {
        adm.linkedadmissions.forEach(ladm => {
          let linkedAdm =  e.admissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedAdm != null)
          {
            totalNumberOfLinked += Number(linkedAdm.noofpeople);
          }
        });

        if(adm.onlysetmax)
        {
          adm.max = Number(totalNumberOfLinked);
          if (adm.max < adm.min)
            adm.max = adm.min;
          adm.fixed = false;
        }
        else
        {
          adm.noofpeople = Number(totalNumberOfLinked);
          adm.fixed = true;
        }
      }
    });

  }

  updateAdm(e)
  {
    this.doLinkedAdmissions(e)

    this.ListEvents.forEach(element => {
      element.eventdates.forEach(element => {
        this.selectedDateTime[element.index] = false;
      });
    });
  }

  bookSlot(st, e)
  {
    this.SelectedAdmissions = [];
    this.http.get(bmxurl + "package/" + this.VenueID + "/" + e.packageid).subscribe(response => {
      this.httpResponse = response;

      this.SelectedPackage = this.httpResponse.data;

      this.MinAdmissions = this.SelectedPackage.min;
      var totalAdmissions = 0;

      e.admissions.forEach(adm => {
        var newAdm: admission = {
          id: adm.id,
          admissionid: adm.admissionid,
          classid: adm.classid,
          noofpeople: adm.noofpeople,
          price: adm.price,
          name: adm.name,
          index: adm.index,
          max: adm.max,
          min: adm.min,
          venueid: adm.venueid,
          disabled: adm.disabled,
          admissionClass: adm.admissionClass,
          bookingwizardtext: adm.bookingwizardtext,
          fixedPrice: adm.fixedPrice,
          participant: adm.participant,
          mustinclude: adm.mustinclude,
          uselinkeddiscounts: adm.uselinkeddiscounts,
          linkedadmissions: adm.linkedadmissions,
          linkeddiscounts: adm.linkeddiscounts,
          uselinkedadmissions: adm.uselinkedadmissions,
          maxlinkeddiscounts: adm.maxlinkeddiscounts,
          maxlinkedadmissions:adm.maxlinkedadmissions,
          fixed: adm.fixed,
          onlysetmax: adm.onlysetmax
        }

        totalAdmissions += adm.noofpeople;
        this.SelectedAdmissions.push(newAdm);
      });

      if(totalAdmissions < this.MinAdmissions) 
      {
        this.minNumberOfPeopleMet = false;
      }

      if(this.minNumberOfPeopleMet)
      {
        let venueName = localStorage.getItem("SmartVenueName");
        let passedTime = st.timeString;
    
        localStorage.setItem("EventBookingPassed", "TRUE");
        localStorage.setItem("EventBookingSelectedTime", passedTime);
        localStorage.setItem("EventBookingSelectedDate", this.SelectedDate.toString());
        localStorage.setItem("EventBookingSelectedPackage", JSON.stringify(this.SelectedPackage));
        localStorage.setItem("EventBookingSelectedAdmissions", JSON.stringify(this.SelectedAdmissions));
        localStorage.setItem("EventBookingCustomerSelectedResources", JSON.stringify(this.customerSelectedResources));
    
        this.router.navigateByUrl("booking/"+ venueName);
      }
    }, error => {
    });
  }

  
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  IsDiscountValid(p)
  {
    if(p.earlybirddiscount)
    {
      if(p.discountrangetype == 0)
      {
        let dstartdate: Date = new Date(p.discountstart);
        let dstartend: Date = new Date(this.addDays(new Date(p.discountend) ,1));

        if(dstartdate.getTime() <= this.CurrentDate.getTime() && dstartend.getTime() >= this.CurrentDate.getTime())
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        let today = new Date();
        today = new Date(today.toDateString());
        let bookingdate = new Date(this.SelectedDate);
        let diff = bookingdate.getTime() - today.getTime();
        let days = diff / (1000 * 3600 * 24);
        if(days >= p.discountdaysbefore)
        {
          return true;
        }
        else
        {
          return false;
        }
      }

    }
    else
    {
      return false;
    }

  }

  getPackageResources(PackageID, e)
  {
    this.http.get(bmxurl + "package/" + this.VenueID + "/" + PackageID).subscribe(response => {
      this.httpResponse = response;

      let stringStartDate = (this.httpResponse.data as cPackage).startDate.toString();
      let stringStartDateYear = stringStartDate.substring(0, 4);
      let stringStartDateMonth = stringStartDate.substring(5, 7);
      let stringStartDateDay = stringStartDate.substring(8, 10);

      let stringEndDate = (this.httpResponse.data as cPackage).endDate.toString();
      let stringEndDateYear = stringEndDate.substring(0, 4);
      let stringEndDateMonth = stringEndDate.substring(5, 7);
      let stringEndDateDay = stringEndDate.substring(8, 10);

      this.SelectedPackage = {
        id: (this.httpResponse.data as cPackage).id,
        packageId: (this.httpResponse.data as cPackage).packageId,
        name: (this.httpResponse.data as cPackage).name,
        eventid: (this.httpResponse.data as cPackage).eventid,
        description: (this.httpResponse.data as cPackage).description,
        imageurl: (this.httpResponse.data as cPackage).imageurl,
        message: (this.httpResponse.data as cPackage).message,
        disabled: (this.httpResponse.data as cPackage).disabled,
        venueID: (this.httpResponse.data as cPackage).venueID,
        resources: (this.httpResponse.data as cPackage).resources,
        startTimes: (this.httpResponse.data as cPackage).startTimes,
        admissions: (this.httpResponse.data as cPackage).admissions,
        packageLength: (this.httpResponse.data as cPackage).packageLength,
        questionnaire: (this.httpResponse.data as cPackage).questionnaire,
        min: (this.httpResponse.data as cPackage).min,
        max: (this.httpResponse.data as cPackage).max,
        tags: (this.httpResponse.data as cPackage).tags,

        startDate: new Date(stringStartDateYear, stringStartDateMonth - 1, stringStartDateDay, 0,0,0),
      
        endDate: new Date(stringEndDateYear, stringEndDateMonth - 1, stringEndDateDay, 0,0,0),

        visibility: (this.httpResponse.data as cPackage).visibility,
        colour: (this.httpResponse.data as cPackage).colour,
        packageRule: (this.httpResponse.data as cPackage).packageRule,
        packageRestriction: (this.httpResponse.data as cPackage).packageRestriction,
        extras: (this.httpResponse.data as cPackage).extras,
        publicDepositPolicy: (this.httpResponse.data as cPackage).publicDepositPolicy,
        venueDepositPolicy: (this.httpResponse.data as cPackage).venueDepositPolicy,
        additionalresources: (this.httpResponse.data as cPackage).additionalresources,
        confirmation: (this.httpResponse.data as cPackage).confirmation,
        originatingid:  (this.httpResponse.data as cPackage).originatingid,
        bookingscannotbemadewithinxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: (this.httpResponse.data as cPackage).cannotmakebookingstoday,
        discounttype: (this.httpResponse.data as cPackage).discounttype,
        discountamount: (this.httpResponse.data as cPackage).discountamount,
        discountcode: (this.httpResponse.data as cPackage).discountcode,
        discountstart: new Date((this.httpResponse.data as cPackage).discountstart),
        discountend: new Date((this.httpResponse.data as cPackage).discountend),
        earlybirddiscount: (this.httpResponse.data as cPackage).earlybirddiscount,
        discountrangetype: (this.httpResponse.data as cPackage).discountrangetype,
        discountdaysbefore: (this.httpResponse.data as cPackage).discountdaysbefore,
      }

      this.customerSelectedResources = [];
      this.SelectedResources = [];
  
      this.SelectedAdmissions = [];
  
      e.admissions.forEach(adm => {
        var newAdm: admission = {
          id: adm.id,
          admissionid: adm.admissionid,
          classid: adm.classid,
          noofpeople: adm.noofpeople,
          price: adm.price,
          name: adm.name,
          index: adm.index,
          max: adm.max,
          min: adm.min,
          venueid: adm.venueid,
          disabled: adm.disabled,
          admissionClass: adm.admissionClass,
          bookingwizardtext: adm.bookingwizardtext,
          fixedPrice: adm.fixedPrice,
          participant: adm.participant,
          mustinclude: adm.mustinclude,
          uselinkeddiscounts: adm.uselinkeddiscounts,
          linkedadmissions: adm.linkedadmissions,
          linkeddiscounts: adm.linkeddiscounts,
          uselinkedadmissions: adm.uselinkedadmissions,
          maxlinkeddiscounts: adm.maxlinkeddiscounts,
          maxlinkedadmissions:adm.maxlinkedadmissions,
          fixed: adm.fixed,
          onlysetmax: adm.onlysetmax
      }
  
      this.SelectedAdmissions.push(newAdm);
      });
  
      this.SelectedPackage.resources.forEach(res => {
        var newRes: any = {
          groupname: uuidv4(),
          isevent: res.isevent,
          onlyRunThisPackageAtSameTime: res.onlyRunThisPackageAtSameTime,
          packageRule: res.packageRule,
          resourcegroupid: res.resourcegroupid,
          resourceIndex: res.resourceIndex,
          restrictExactSimiltaneousStartTimes: res.restrictExactSimiltaneousStartTimes,
          restrictionID: res.restrictionID,
          restrictionName: res.restrictionName,
          startTime: res.startTime,
          startTimeOffset: res.startTimeOffset,
          venuedate: res.venuedate,
          wascontainer: res.wascontainer,

          bookingId: res.bookingid,
          chain: res.chain,
          id: res.id,
          resourceId: res.resourceId,   
          name: res.name,
          endTime: res.endTime,
          eventid: res.eventid,
          useClasses: res.useClasses,
          exclusive: res.exclusive,
          concurrentUses: res.concurrentUses,
          maxConcurrent: res.maxConcurrent,
          disabled: res.disabled,
          venueId: res.venueId,
          colour: res.colour,
          orderindex: res.orderindex,
          length: res.length,
          classes:  res.classes,
          resources: res.resources,
          isresourcecontainer: res.isresourcecontainer,
          isconstructed: res.isconstructed,
          resourcegroupname: res.resourcegroupname,
          price: res.price,
          fixedprice: res.fixedprice,
          tags: res.tags,
          resourcegroup: res.resourcegroup,
          selectionmode: res.selectionmode,
          selectableresources: res.selectableresources,
          startwhen: res.startwhen,
          StartTimeOffset: res.startTimeOffset,
          numofminutes: res.numofminutes,
          optional: res.optional,
          quantityselector: res.quantityselector,
          quantity: res.quantity,
          mode: res.mode,
          inline: res.inline,
          ignore: res.ignore,
          admissions: res.admissions,
          inProgress: res.inProgress,
          originatingid: res.originatingid,
          wasoptional: res.wasoptional,
          wasselected: res.wasselected
      }
  
      this.SelectedResources.push(newRes);
      });
  
      if(this.SelectedPackage.questionnaire != null)
      {
        this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
          question.answers = {
            bookingid: "",
            bookingquestionnaireid:  null,
            bookingquestionid: question.bookingquestionid,
            birthdayanswers: null,
            allergyanswer: {
              id: null,
              allergypresent: false,
              allergytext: ""
            },
            freetextanswer: "",
            numberanswer: null,
            dateanswer:  null,
            multichoicesingleanswer: {
              id: null,
              listitemtext: ""
            },
            multichoicemultianswer: []
          }
        });
      }
  
      this.SelectedResources.forEach(element => {
        if(element.mode == "COMPLEX")
        {
          if(element.selectionmode == "SYSTEMANY" || element.selectionmode == "SYSTEMLIST" || element.selectionmode == "") 
          {
            this.includesComplexResources = false;
          }
          else{
            this.includesComplexResources = true;
          }
          
        }
        else{
          this.includesComplexResources = false;
        }
        if(element.selectionmode != "SYSTEMANY" && element.selectionmode != "SYSTEMLIST")
        { 
          this.systemChoosesAllResources = false;
        }
        else
        {
          this.systemChoosesAllResources = true;
        }
  
        if(element.optional)
        {
          this.includesOptionalResources = true;
        }
      });
  
      if(!this.includesComplexResources || this.systemChoosesAllResources)
      {
        this.customerSelectedResources = this.SelectedPackage.resources;
      }

      this.getCustomerSelectedResources();

      this.getAvailability(e);

    }, error => {
      var err = 0;
    });



  }

  getCustomerSelectedResources()
  {
    this.customerSelectedResources = [];
    
    let index = 0;
    this.SelectedResources.forEach(packRes => {
      if(packRes.optional == true)
      {
        if(this.SelectedSeletableResourceBool[index] == true)
        {
          packRes.ignore = false;
          if(packRes.resources.length > 0)
          {
              if(packRes.isconstructed && packRes.isresourcecontainer)
              {
                let resourceToSave: resource;
                packRes.resources.forEach(selectedRes => {
                  if(selectedRes != null)
                  {
                    if (selectedRes.id == this.SelectedSeletableResource[index])
                    {
                      resourceToSave = selectedRes;
                    }
                  }
                });
                
                packRes.resources = [];
                packRes.resources.push(resourceToSave);
              }
              this.customerSelectedResources.push(packRes);
          }
          else
          {
            packRes.ignore = false;
            this.customerSelectedResources.push(packRes);
          }
        }
        else
        {
          packRes.ignore = true;
          this.customerSelectedResources.push(packRes);
        }
      }
      else
      {
        if (this.SelectedSeletableResource[index] != null)
        {
          //if(packRes.selectionmode != "ANY" && packRes.selectionmode != "SYSTEMANY")
          //{
            let resourceToSave: resource;
            packRes.resources.forEach(selectedRes => {
              if (selectedRes.id == this.SelectedSeletableResource[index])
              {
                resourceToSave = selectedRes;
              }
            });
            
            packRes.resources = [];
            packRes.resources.push(resourceToSave);

            this.customerSelectedResources.push(packRes);

        }
        else
        {
          this.customerSelectedResources.push(packRes);
        }
      }
      index++;
    });




  }

  getAvailability(e){
    let totalNoOfPeople: number = 0;

    this.AvailabilityReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
    
      this.AvailabilityReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
      
   });

    this.AvailabilityReq.packageId = e.packageid;
    this.AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    this.AvailabilityReq.venueid = this.VenueID;
    this.AvailabilityReq.StartTime =  this.SelectedDate.getFullYear() + "-" +  (this.SelectedDate.getMonth() + 1) + "-" +  this.SelectedDate.getDate() + "T00:00:00.000Z";
    this.AvailabilityReq.DiagnosticMode = false;
    this.AvailabilityReq.Resources = this.customerSelectedResources;

    this.http.post<AvailableTimes>(bmxurl + "/Availability",this. AvailabilityReq).subscribe(response => {
      this.startTimes =(response as AvailableTimes)
      let found: Boolean = false;

    this.startTimesFiltered = [];
      this.startTimes.forEach(element => {
        if(element.timeAvailable)
        {
          this.startTimesFiltered.forEach(innerelement => {
            if(innerelement.time == element.time)
              found = true;
          });

          if(!found)
          {
            this.startTimesFiltered.push(element);
          }
          found = false;
        }
      });

      this.doneAvailability = true;

      //If there is only one time, book it.
      if(this.startTimesFiltered.length == 1)
      {
        //this.timeActive = false;
        //this.timeSelected = false;
        //this.bookSlot(this.startTimesFiltered[0]);
      }
      console.log(response);
    }, error => {
      console.log(error);
  })


  }
  }
