//import { NgModule } from '@angular/core';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AccordionModule } from 'primeng/accordion';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { DragDropModule } from 'primeng/dragdrop';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { GMapModule } from 'primeng/gmap';
import { SliderModule } from 'primeng/slider';
import {StepsModule} from 'primeng/steps';
import {BadgeModule} from 'primeng/badge';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {InputMaskModule} from 'primeng/inputmask';
import {BlockUIModule} from 'primeng/blockui';
import {PanelModule} from 'primeng/panel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SafePipe } from './safe.pipe';
//import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";

import {
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalBroadcastService,
  MsalGuard,
  MsalRedirectComponent,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { BuyvoucherComponent } from './buyvoucher/buyvoucher.component';
import { BookingComponent } from './booking/booking.component';
import { TermsComponent } from './terms/terms.component';
import { PaymentComponent } from './payment/payment.component';
import { EventsListComponent } from './events-list/events-list.component';
import { HomeComponent } from './home/home.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.WindermereApi.endpoint, [
      {
          httpMethod: 'GET',
          scopes: [...protectedResources.WindermereApi.scopes_read]
      },
      {
          httpMethod: 'POST',
          scopes: [...protectedResources.WindermereApi.scopes_write]
      },
      {
          httpMethod: 'PUT',
          scopes: [...protectedResources.WindermereApi.scopes_write]
      },
      {
          httpMethod: 'DELETE',
          scopes: [...protectedResources.WindermereApi.scopes_write]
      }
  ]);

  return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    BuyvoucherComponent,
    BookingComponent,
    SafePipe,
    TermsComponent,
    PaymentComponent,
    EventsListComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InputNumberModule,
    DropdownModule,
    FormsModule,
    ToggleButtonModule,
    TableModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    InputTextareaModule,
    NgxSpinnerModule,
    ToastModule,
    MenuModule,
    CalendarModule,
    SelectButtonModule,
    ColorPickerModule,
    AccordionModule,
    MultiSelectModule,
    DragDropModule,
    ChipModule,
    TagModule,
    TabViewModule,
    GMapModule,
    SliderModule,
    StepsModule,
    BadgeModule,
    DialogModule,
    ConfirmDialogModule,
    InputMaskModule,
    BlockUIModule,
    PanelModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    ReactiveFormsModule,
    RadioButtonModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    ConfirmationService,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     //showDialog: true,
    //   }),
    // }, {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
