import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Sentry.init({
//   dsn: "https://52e302192b90fe58eef0692944f5bcb7@o4506897431330816.ingest.us.sentry.io/4506897448960000",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],

//   tracesSampleRate: 1.0,
 
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//   replaysSessionSampleRate: 0.1, 
//   replaysOnErrorSampleRate: 1.0, 
// });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// enableProdMode();
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then((success) => console.log('Bootstrap success'))
//   .catch((err) => console.error(err));
