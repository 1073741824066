<link rel="stylesheet" [href]="VenueCSS">

<div *ngIf="VenueID=='' && !BookingTimeElasped">
    <p>Unknown venue</p>
</div>

<p-dialog header="Warning" [(visible)]="showWarning" [style]="{width: '50vw', height: '50vh'}" [baseZIndex]="10000" [modal]="true">
    <div class="field mb-4 col-12">
        <div [innerText]="WarningMessage">
        </div>
    </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="Continue" styleClass="p-button p-button-success" (click)="WarningContinue()"></p-button>
  </ng-template>
  
</p-dialog>

<p-dialog header="Venue Closing" [(visible)]="showVCWarning" [style]="{width: '50vw', height: '50vh'}" [baseZIndex]="10000" [modal]="true">
    <div class="field mb-4 col-12">
        <div [innerHTML]="VCWarningMessage">
        </div>
    </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="Continue" styleClass="p-button p-button-success" (click)="VCContinue()"></p-button>
  </ng-template>
  
</p-dialog>

<div *ngIf="BookingTimeElasped">
    <div class="padding15px">
        <div class="publicImage"><img src={{publicImageURL}} width={{imageWidth}} height={{imageHeight}}></div>
    </div>
    <div class="clearboth"></div>

    <div style="text-align: center;width: 100%;font-size: 17px;font-weight: bold;padding-top: 5vh;padding-bottom: 50vh;padding-left:20px;padding-right:20px">Your booking session has timed out, please click on the button below to restart.
        <div style="text-align: center; width: 100%; padding-top:40px;" class="StartAgainButton">
            <p-button class="p-button" class="Margin0Padding0" label="Click" icon="pi pi-times" iconPos="left" label="Make a new Booking" (click)="restartBooking()"></p-button>
        </div>
    </div>
    
</div>
<div *ngIf="VenueID!='' && !BookingTimeElasped">

    <div *ngIf="showTimer">
        <div class="bookingCountdown" *ngIf="ActualTimeRemaining <= 0">
            Time remaining to complete this booking:  {{ 0 | date:'mm:ss' }}
        </div>

        <div class="bookingCountdown" *ngIf="ActualTimeRemaining > 0">
            Time remaining to complete this booking:  {{ ActualTimeRemaining | date:'mm:ss' }}
        </div>
    </div>
        
    <div *ngIf="!BookingComplete && !PaymentInProgress" class="PaymentTotalBar">
        <div *ngIf="totalAdmissionsPrice != null && totalAdmissionsPrice > 0" class="PaddingTop6">
            Booking Value: {{this.NewBooking.total | currency:'GBP'}}
        </div>
        <div *ngIf="!BookingHasValue" class="Width100">
            <span></span>
        </div>
    </div>

    <div *ngIf="PaymentInProgress" class="PaymentInProgress">
        <span class="MakingPayment">
        <div>Making Payment, Please wait...</div>
        <p-progressSpinner class="ProgressSpinner"></p-progressSpinner>
        </span>
    </div>

    <p-dialog header="Terms & Conditions" [(visible)]="displayModalTerms" class="TermsAndConditions" [baseZIndex]="10000" [modal]="true">
        <div [innerHTML]="TermsAndConditions"></div>
    </p-dialog>

    <div *ngIf="!BookingComplete && !BookingTimeElasped">
        <div class="padding15px">
            <div class="publicImage"><a href="{{ReturnURL}}" title="Return to website"><img src={{publicImageURL}} width={{imageWidth}} height={{imageHeight}}></a></div>

        <div class="StartAgainButton" class="DisplayNone">
            <p-button class="p-button" class="Margin0Padding0" label="Click" icon="pi pi-times" iconPos="left" label="Start Again" (click)="startAgain()"></p-button>
        </div>
        <div class="clearboth"></div>

        <div style="clear:both; width:100%"></div>
       
        <div *ngIf="packages">
        <p-accordion>
            <p-accordionTab  [(selected)]="dateSelected" [disabled]="dateActive">
                <p-header  class="Width100">
                    <div class="Width100">
                        <span class="pi pi-calendar" class="MarginRight10" [dateFormat]="dd/mm/yy"></span>{{CalendarHeader}}
                    </div>
                </p-header>


            <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="ddlPackage" class="font-medium FloatLeftWidth100">Date</label>
                    <div class="DateSelection">
                        <p-calendar [selectOtherMonths]="true" [touchUI]="true"  [showButtonBar]="true" firstDayOfWeek="1" [(ngModel)]="SelectedDate" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()"></p-calendar>  
                    </div>
                    <div class="FloatLeft">
                        <button pButton type="button"class="p-element p-ripple p-button p-component DateButtons" (click)="switchToToday()">
                            <span>Today</span>
                        </button>

                        <button pButton type="button" class="p-element p-ripple p-button p-component DateButtons" (click)="switchTomorrow()">
                            <span>Tomorrow</span>
                        </button>

                        <button pButton type="button" class="p-element p-ripple p-button p-component DateButtons" (click)="switchToSaturday()">
                            <span>Saturday</span>
                        </button>

                        <button pButton type="button" class="p-element p-ripple p-button p-component DateButtons" (click)="switchToSunday()">
                            <span>Sunday</span>
                        </button>
                    </div>
                </div>
            </div>
            </div>

            </p-accordionTab>
            <div id="topOfPackages"></div>
            <p-accordionTab [(selected)]="packageSelected" [disabled]="packageActive">
                <p-header class="Width100">
                    <div class="Width100">
                        <span class="pi pi-box" class="MarginRight10"></span>{{PackageHeader}}
                    </div>
                </p-header>
                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">
            <div class="grid formgrid p-fluid">
                <div *ngIf="packageGroups.length == 0">There are no packages available for the date chosen.</div>
                <div *ngIf="packageGroups.length > 1" style="width:100%;">
                    <div *ngIf="packageGroups.length > 0">Select the Package groups you are interested in below (You can select multiple groups)</div>
                    <div class="Width100">
                        <span *ngFor="let pg of packageGroups" class="PackageGroups">
                            <p-toggleButton  [(ngModel)]="checked[pg.packagegroupid]" onLabel="{{pg.packagegroupname}}" offLabel="{{pg.packagegroupname}}" (onChange) = "changedPGSelection(pg)"></p-toggleButton>
                        </span>
                    </div>
                </div>
                <div class="clearboth"></div>

                <div class="surface-card p-4 shadow-2 border-round packageCard" *ngFor="let p of packages">
                    <div id="image">
                        <img src="{{p.imageurl}}" class="Width100" style="cursor:pointer" (click)="donePackage(p)">
                    </div>
                    
                    <div *ngIf="IsDiscountValid(p)" style="width: 100%;background-color: #d30000;color: white;text-align: center;padding: 15px;font-weight: bold;">
                        <span>
                            <img src="assets/images/littlebird.png" style="width: 50px;padding-right: 10px;">
                        </span>
                        <span>
                            You are entitled to an early bird discount
                        </span>
                    </div>

                    <div class="PackageName">{{ p.name }}</div>
                    <div  id="dv{{p.packageId}}collapse" class="PackageDescription" [innerHTML]="p.description != null ? (p.description.length < 100 ? p.description : (p.description.substring(0,100)) + '...') : ''">  
                    </div>

                    <div id="dv{{p.packageId}}expanded" hidden="true" class="ExpandDescription" [innerHTML]="p.description">  
                    </div>

                    <div class="Height120">

                    </div>
                    <div class="ExpandDescription2">
                        <button style="margin-right:5px; margin-bottom:10px" *ngIf="p.description.length > 99" pButton type="button" value="Show More..." id="dv{{p.packageId}}toggleButton"  class="p-button-secondary p-button-text Width178" (click)="toggleMore('dv'+p.packageId)">
                            <span>Show More...</span>
                        </button>
                        <div>
                            <p-button label="Book this Package" (click)="donePackage(p)">
                            </p-button>
                        </div>
                    </div>
                </div>

            </div>
            </div>
            </p-accordionTab>
            <div id="topOfAdmissions"></div>
            <p-accordionTab [(selected)]="admissionsSelected" [disabled]="admissionsActive">
                <p-header class="Width100">
                    <div class="Width100">
                        <span class="pi pi-users" class="MarginRight10"></span> {{AdmissionHeader}}
                    </div>
                </p-header>
                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12">
                        <div *ngIf="totalAdmissionInRange">
                            <b>Total Admissions:</b> {{totalAdmissions}}
                            <br/><br/>
                        </div>
                        <div *ngIf="SelectedPackage != null && !totalAdmissionInRange && totalAdmissions < SelectedPackage.min" class="ForecolourRed">
                            <b>Total Admissions:</b> {{totalAdmissions}} - Please select at least {{SelectedPackage.min}} admissions.
                            <br/><br/>
                        </div>
                        <div *ngIf="SelectedPackage != null && !totalAdmissionInRange && totalAdmissions > SelectedPackage.max" class="ForecolourRed">
                            <b>Total Admissions:</b> {{totalAdmissions}} - Please select {{SelectedPackage.max}} admissions or less.
                            <br/><br/>
                        </div>
                        <div class="packageSelection clr" *ngFor="let a of (SelectedAdmissions)">
                            <div *ngIf="a.mustinclude" class="PackageSelectLabel MarginBottom8">
                                {{a.bookingwizardtext}}&nbsp;<b>{{a.price | currency:'GBP'}}</b> *:
                            </div>
                            <div *ngIf="!a.mustinclude" class="PackageSelectLabel MarginBottom8">
                                {{a.bookingwizardtext}}&nbsp;<b>{{a.price | currency:'GBP'}}</b>:
                            </div>

                            <div class="packageSelect" class="PackageSelect">
                            <p-inputNumber [(ngModel)]="SelectedAdmissions[a.index].noofpeople"  [disabled]="a.fixed" [id]="'ad'+a.name" [showButtons]="true" max="{{a.max}}" min="{{a.min}}" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="sumAdmissions()" (onBlur)="sumAdmissions()" decrementButtonClass="p-button-secondary decrementButton"
                            incrementButtonClass="p-button-secondary incrementButton" 
                            incrementButtonIcon="pi pi-plus" 
                            decrementButtonIcon="pi pi-minus"></p-inputNumber>
                            </div>
                        </div>
                        <div class="clearboth">
                            <button pButton type="button" class="p-button p-button SelectAdmissions" (click)="doneAdmissions()">
                                <span>Select these Admissions</span>
                                <span class="pi pi-chevron-right MarginLeft8" ></span>
                            </button>
                        </div>
                        <br />
                        * denotes that you must have at least one of these.
                        </div>
                    </div>
                </div>

            </p-accordionTab>
            <div id="topOfComplexResources"></div>
            <p-accordionTab *ngIf="includesComplexResources" [(selected)]="partyroomsSelected" [disabled]="partyroomsActive">
                <p-header class="Width100">
                    <div class="Width100">
                        <span class="pi pi-sitemap" class="MarginRight10"></span> {{SelResourceHeader}}
                    </div>
                </p-header>
                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">

                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12">
                            <div class="packageSelection clr" *ngFor="let a of (SelectedResources)">
                                <div *ngIf="a.mode == 'COMPLEX' && a.selectionmode != 'SYSTEMANY' && a.selectionmode != 'SYSTEMLIST' && a.selectionmode != ''"  class="PackageSelectLabel MarginBottom8">
                                    <div class="MarginBottom10" *ngIf="!a.optional"><b>Please select one of these {{a.name}}:</b></div>
                                    
                                    <div class="MarginBottom10" *ngIf="a.optional">
                                        <div class="OptionalResources"><p-inputSwitch [(ngModel)]="SelectedSeletableResourceBool[a.orderindex]"></p-inputSwitch></div>
                                        <div *ngIf="!a.isconstructed == true" class="OptionalTitle"><b>Would you like a {{a.name}}?</b></div>
                                        <div *ngIf="a.isconstructed == true" class="OptionalTitle"><b>Would you like to choose a {{a.name}}?</b></div>
                                        <div class="clearboth"></div>
                                    </div>
        
                                    <div *ngIf="a.optional && SelectedSeletableResourceBool[a.orderindex] && a.isconstructed == true" >
                                        <div class="packageSelection clr" *ngFor="let r of (a.resources)">
                                            <p-radioButton class="PackageSelection" name="{{a.groupname}}" value="{{r.id}}" label="{{r.name}}" [(ngModel)]="SelectedSeletableResource[a.orderindex]"></p-radioButton>
                                        </div>
                                        <div class="clearboth"></div>
                                    </div>

                                    <div *ngIf="!a.optional" >
                                        <div class="packageSelection clr" *ngFor="let r of (a.resources)">
                                            <p-radioButton class="PackageSelection" name="{{a.groupname}}" value="{{r.id}}" label="{{r.name}}" [(ngModel)]="SelectedSeletableResource[a.orderindex]"></p-radioButton>
                                        </div>
                                    </div>
                                    <div *ngIf="!validselection[a.orderindex] && validselection[a.orderindex] != null" class="text-danger" class="MarginLeft">
                                        <div class="clearboth"></div>
                                        <span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must select from the list above.
                                    </div>

                                    <div *ngIf="a.quantityselector == 'CUSTOM' && a.optional" class="MarginLeft10">
                                        <div class="PackageSelectLabel" class="MarginBottom8">
                                            How many of the above resource would you like?
                                        </div>
                                        <div class="packageSelect" class="PackageSelect">
                                        <p-inputNumber [(ngModel)]="a.quantity" [showButtons]="true" max="100" min="1" buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="p-button-secondary decrementButton"
                                        incrementButtonClass="p-button-secondary incrementButton" 
                                        incrementButtonIcon="pi pi-plus" 
                                        decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                        </div>
                                    </div>

                                    <hr>
                                </div>

                                <div *ngIf="a.mode == 'COMPLEX'
                                && a.optional && a.selectionmode == ''"  class="PackageSelectLabel MarginBottom8">
                                    <div class="MarginBottom10" *ngIf="!a.optional"><b>Please select one of these {{a.name}}:</b></div>
                                    
                                    <div class="MarginBottom10" *ngIf="a.optional">
                                        <div class="ComplexOptional"><p-inputSwitch [(ngModel)]="SelectedSeletableResourceBool[a.orderindex]"></p-inputSwitch></div>
                                        <div *ngIf="!a.isconstructed == true" class="WouldYouLikea"><b>Would you like a {{a.name}}?</b></div>
                                        <div *ngIf="a.isconstructed == true" class="WouldYouLikea"><b>Would you like to choose a {{a.name}}?</b></div>
                                        <div class="clearboth"></div>
                                    </div>

                                    <div *ngIf="SelectedSeletableResourceBool[a.orderindex] && a.quantityselector == 'CUSTOM'" class="MarginLeft10">
                                        <div class="PackageSelectLabel" class="MarginBottom8">
                                            How many of the above resource would you like?
                                        </div>
                                        <div class="packageSelect" class="PackageSelect">
                                        <p-inputNumber [(ngModel)]="a.quantity" [showButtons]="true" max="100" min="1" buttonLayout="horizontal" spinnerMode="horizontal"
                                        decrementButtonClass="p-button-secondary decrementButton"
                        incrementButtonClass="p-button-secondary incrementButton" 
                        incrementButtonIcon="pi pi-plus" 
                        decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                        </div>
                                    </div>

                                    <hr>
                                </div>
                            </div>

                        <div class="clearboth">
                            <button pButton type="button" class="p-button p-button PackageRoomSelection" (click)="donePartyRooms()">
                                <span>Add this Selection</span>
                                <span class="pi pi-chevron-right" class="MarginLeft8"></span>
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
            <div id="topOfTimes"></div>
            <p-accordionTab [(selected)]="timeSelected" [disabled]="timeActive">
                <p-header class="Width100">
                    <div class="Width100">
                        <span class="pi pi-clock" class="MarginRight10"></span> {{TimeHeader}}
                    </div>
                </p-header>
                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">  
                    
                <div>    
                    <div *ngFor="let st of (startTimesFiltered)" class="times">
<!-- 
                        <img *ngIf="!st.timeAvailable" style="position: absolute;z-index: 9999;width: 78px;margin-top: -8px;margin-left: -6px;" src="assets/images/Full.png"> -->

                        <button *ngIf="st.timeAvailable" name="fieldName" ngDefaultControl class="p-button StartTimesFiltered" [(ngModel)]="timeSelected" (click)="bookSlot(st)">
                            <div style="width:100%; text-align: center;">
                                {{ st.time | date:'HH:mm' : 'UTC'}}
                            </div>
                        </button>

                        <!-- <button *ngIf="!st.timeAvailable" name="fieldName" ngDefaultControl class="p-button StartTimesFiltered" [(ngModel)]="timeSelected">
                            <div style="width:100%; text-align: center;">
                                {{ st.time | date:'HH:mm' : 'UTC'}}
                            </div>
                        </button> -->
                       
                    </div>
                </div>
                
                <div class="ClearHeight75"></div>

                <div *ngIf="startTimesFiltered === null || startTimesFiltered === undefined || startTimesFiltered.length == 0" class="NoSlotsAvailable">There are no times available that match your criteria.</div>
            </div>
            </p-accordionTab>
            <div id="topOfQuestions"></div>
            <p-accordionTab *ngIf="SelectedPackage && SelectedPackage.questionnaire" [(selected)]="bookingQuestionsSelected" [disabled]="bookingQuestionsActive">
                <p-header style="width:100%">
                    <div style="width:100%">
                        &nbsp;Booking Questions
                    </div>
                </p-header> 
                <div class="surface-card p-4 shadow-2 border-round">
                    <div class="grid formgrid p-fluid">
                        <div *ngFor="let bq of SelectedPackage.questionnaire.bookingquestions" class="field mb-4 col-12">
                            <div *ngIf="bq.questiontype=='FREETEXT' && bq.answers != null">
                                <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                <input id="bq{{bq.id}}" type="text" [(ngModel)]="bq.answers.freetextanswer" pInputText>
                                <div *ngIf="!mustanswerfreetext[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must this question.</div>
                            </div>
                            
                            <div *ngIf="bq.questiontype=='BIRTHDAY' && bq.answers != null">
                                <div style="width:100%; float:left;"><label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label></div>                      
                                <div *ngFor="let ibq of birthdayitems" style="width: 100%;">
                                    <!-- <div style="width:100%">
                                        <div style="min-width: 300px; width:32%; float:left; margin-right:1%;margin-bottom:10px;"><input id="bq{{bq.id}}" type="text" placeholder="Child's Name" [(ngModel)]="ibq.name" pInputText></div>
                                        <div *ngIf="bq.showage" style="min-width: 300px; width:32%; float:left; margin-right:1%;margin-bottom:10px;">
                                            <p-inputNumber [showButtons]="true" placeholder="Child's Age" [(ngModel)]="ibq.age" buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="p-button-secondary decrementButton"
                                            incrementButtonClass="p-button-secondary incrementButton" 
                                            incrementButtonIcon="pi pi-plus" 
                                            decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                        </div>
                                        <div *ngIf="bq.showdob" style="min-width: 300px; width:32%; float:left;margin-bottom:10px;"><p-calendar [selectOtherMonths]="true" placeholder="Child's Birthdate"  firstDayOfWeek="1" [(ngModel)]="ibq.birthdate" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  </div>
                                        <div *ngIf="birthdayitems.length > 1">
                                            <button pButton type="button" style="height: 50px; width: 58px;" class="p-button-danger p-button" (click)="deleteBirthdayRow(ibq.id)">
                                                <span class="pi pi-trash"></span>
                                            </button>
                                        </div>
                                    </div> -->
                                    <div style="width:100%">
                                        <div>
                                            <div style="min-width: 300px; width:31%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                                Child's Name<br>
                                                <input id="bq{{bq.id}}" type="text" [(ngModel)]="ibq.name" pInputText>
                                            </div>
                                            
                                            <div *ngIf="bq.showage" style="min-width: 300px; width:31%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                                Child's Age<br>
                                                <!-- <p-inputNumber [showButtons]="true" [(ngModel)]="ibq.age"  buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber> -->
                                                <p-dropdown [showClear]="true" [options]="dobage" [(ngModel)]="ibq.age" placeholder="Select age" optionLabel="name" optionValue="value"></p-dropdown>
                                            </div>
                                            
                                            <div *ngIf="bq.showdob" style="min-width: 300px; width:31%; float:left;margin-bottom:10px;margin-top: 5px;">
                                                Child's Birthdate<br>
                                                <p-dropdown [style]="{'float': 'left', 'width': '30%' }" optionLabel="name" optionValue="value" [options]="caldays" [(ngModel)]="ibq.dobday" placeholder="--"></p-dropdown>
                                                <p-dropdown [style]="{'float': 'left', 'width': '30%' }"  optionLabel="name" optionValue="value" [options]="calmonths" [(ngModel)]="ibq.dobmonth" placeholder="--"></p-dropdown>
                                                <p-dropdown [style]="{'float': 'left', 'width': '40%' }"  optionLabel="name" optionValue="value" [options]="calyears" [(ngModel)]="ibq.dobyear" placeholder="----"></p-dropdown>
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div *ngIf="!DOBIsValid" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Please enter a valid date.</div>
                                            
                                        <div style="width:100%">
                                            <div *ngIf="bq.showgender" style="min-width: 300px; width:48%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                                Child's Gender<br>
                                                <p-selectButton id="useclasses" [style]="{'min-width' : '300px'}" [options]="stateGenderOptions" [(ngModel)]="ibq.gender" ></p-selectButton>
                                            </div>
                                            
                                            <div *ngIf="bq.showinterests" style="min-width: 300px; width:48%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                                Child's Interests<br>
                                                <input id="bq{{bq.id}}" type="text" [(ngModel)]="ibq.interests" pInputText>
                                            </div>
                                        </div>
                                        <div *ngIf="birthdayitems.length > 1 && !bq.linkedtoparticipants" style="width: 4%;margin-top: 29px;margin-left: 7px;float: left;">
                                            <button pButton type="button" style="height: 50px; width: 58px;" class="p-button-danger p-button" (click)="deleteBirthdayRow(ibq.id)">
                                                <span class="pi pi-trash"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>
                                <div style="clear:both;"></div>
                                <div style="width:100%" *ngIf="!bq.linkedtoparticipants">
                                    <button pButton type="button" style="height: 50px; width: 225px;margin-top:20px;" class="p-button p-button" (click)="addBirthdayRow()">
                                        <span>Add another child</span>
                                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                                    </button>
                                </div>
                                 <div *ngIf="!mustanswerbirthdays[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide the details of at least one child.</div>
                            </div>
                            
                            <div *ngIf="bq.questiontype=='ALLERGY' && bq.answers != null">
                                <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                <p-selectButton id="allergy" [style]="{'width': '100%', 'max-width' : '230px'}" [options]="stateOptionAllergy" [(ngModel)]="bq.answers.allergyanswer.allergypresent"></p-selectButton>
                                
                                <div *ngIf="bq.answers.allergyanswer.allergypresent == null" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide an answer to whether an allergy is present or not..</div>
                                
                                <div *ngIf="bq.answers.allergyanswer.allergypresent==true">
                                    <label for="bq{{bq.id}}" style="margin-top:20px;" class="font-medium">Please specify any allergy details below</label>
                                    <input id="bq{{bq.id}}" type="text" [(ngModel)]="bq.answers.allergyanswer.allergytext" pInputText>
                                </div>
                                <div *ngIf="!mustanswerallergies[bq.listorder] && bq.answers.allergyanswer.allergypresent==true" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide allergy details.</div>
                            </div>
                            
                            <div *ngIf="bq.questiontype=='MULTICHOICESINGLE' && bq.answers != null">
                                <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                <div>
                                    <p-dropdown [options]="bq.listitems" placeholder="Please select an option" optionLabel="listitemtext" [(ngModel)]="bq.answers.multichoicesingleanswer"></p-dropdown>
                                </div>
                                <div *ngIf="!mustanswermultichoicesingleanswer[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and choose an item.</div>
                            </div>
                            
                            <div *ngIf="bq.questiontype=='MULTICHOICEMULTIPLE' && bq.answers != null">
                                <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                <div>
                                    <p-multiSelect [options]="bq.listitems" placeholder="Please select one or more options" optionLabel="listitemtext" [(ngModel)]="bq.answers.multichoicemultianswer"></p-multiSelect>
                                </div>
                                <div *ngIf="!mustanswermultichoicemultianswer[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and choose one or more items.</div>
                            </div>
                            
                            <div *ngIf="bq.questiontype=='DATE' && bq.answers != null">
                                <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                <p-calendar [selectOtherMonths]="true" firstDayOfWeek="1" [(ngModel)]="bq.answers.dateanswer" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
                                <div *ngIf="!mustanswerdate[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and supply a date.</div>
                            </div>
                            
                            <div *ngIf="bq.questiontype=='NUMBER' && bq.answers != null">
                                <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                <p-inputNumber [showButtons]="true" buttonLayout="horizontal" [(ngModel)]="bq.answers.numberanswer" spinnerMode="horizontal" decrementButtonClass="p-button-secondary decrementButton"
                                incrementButtonClass="p-button-secondary incrementButton" 
                                incrementButtonIcon="pi pi-plus" 
                                decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                <div *ngIf="!mustanswernumber[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and supply a number.</div>
                            </div>
                            <hr style="margin-bottom:0px;margin-top:20px">
                        </div>
                    </div>
    
                    <div style="clear:both;">
                        <button pButton type="button" style="height: 50px; width: 155px;margin-top:20px;" class="p-button p-button" (click)="doneBookingQuestions()">
                            <span>Next</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button> &nbsp;
                    </div>
                </div>
    
            </p-accordionTab>
            <div id="topOfExtras"></div>
            <p-accordionTab *ngIf="PackageHasExtras" [(selected)]="extraSelected" [disabled]="extraActive">
                <p-header class="Width100">
                    <div class="Width100">
                        <span class="pi pi-star" class="MarginRight10"></span> {{ExtrasHeader}}
                    </div>
                </p-header>
                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12">
                            <div class="packageSelection clr" *ngFor="let pe of (packageExtras)">

                                <div class="PackageSelectLabel MarginBottom8">
                                    <span  style="width:100%;">{{pe.name}} (<strong>{{pe.price | currency:'GBP'}}</strong>)</span><br>
                                    <span  style="width:100%;font-size: 12px;">{{pe.description}}</span>
                                </div>
                                <div class="packageSelect" class="PackageSelect">
                                    <p-inputNumber [(ngModel)]="pe.qty" [showButtons]="true" max="{{pe.max}}" min="{{pe.min}}" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="sumExtras()" (onBlur)="sumExtras()" decrementButtonClass="p-button-secondary decrementButton"
                                    incrementButtonClass="p-button-secondary incrementButton" 
                                    incrementButtonIcon="pi pi-plus" 
                                    decrementButtonIcon="pi pi-minus"></p-inputNumber>
                                </div>
                                <div *ngIf="(pe.question!='' && pe.question != null) && pe.qty > 0">
                                    <div style="width:100%;margin-bottom: 10px;">
                                        {{pe.question}}
                                    </div>
                                    <div>
                                        <input style="width: 100%;margin-bottom: 20px;" type="text" [(ngModel)]="pe.answer" pInputText>
                                    </div>
                                </div>
                                <div><strong>Total for this Extra: {{(pe.qty * pe.price) | currency:'GBP'}}</strong></div>
                                <hr>
                            </div>

                            <div *ngIf="!allAnswersValid" class="text-danger" Style="margin-left:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer all Questions.</div>
                            <div class="TotalExtras">Total Extras: {{TotalExtras | currency:'GBP'}}</div>
                            <div class="clearboth"></div>
                            <button pButton type="button" class="p-button p-button DoneExtrasButton" (click)="doneExtras()">
                                <span>Next</span>
                                <span class="pi pi-chevron-right" class="MarginLeft8"></span>
                            </button>
                        </div>
                    </div>
                </div>

            </p-accordionTab>
            <div id="topOfDetails"></div>
            <p-accordionTab [(selected)]="customerSelected" [disabled]="customerActive">
                <p-header class="Width100">
                    <div class="Width100">
                        <span class="pi pi-user" class="MarginRight10"></span> {{CustomerHeader}}
                    </div>
                </p-header>

                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">

                    <div class="grid formgrid p-fluid">

                        <div *ngIf="!ProvideMinimalPersonalData">
                            <div class="field mb-4 col-12"  class="Width100">
                                <label for="firstName" class="font-medium">First Name</label>
                                <input id="firstName" type="text" [(ngModel)]="firstName" pInputText autocomplete="given-name">
                            </div>
                            <div *ngIf="!firstNameValid" class="text-danger PersonalData"><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a first name.</div>
                        </div>
                        
                        <div class="field mb-4 col-12">
                            <label for="lastname" class="font-medium">Last Name</label>
                            <input id="lastname" type="text" [(ngModel)]="lastName" pInputText autocomplete="family-name">
                        </div>
                        <div *ngIf="!lastNameValid" class="text-danger PersonalData"><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a last name.</div>

                        <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                            <label for="address1" class="font-medium">Address 1</label>
                            <input id="address1" type="text" [(ngModel)]="address1" pInputText autocomplete="address-line1">
                        </div>
                        <div *ngIf="!Address1Valid && !ProvideMinimalPersonalData" class="text-danger PersonalData"><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter an address.</div>

                        <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                            <label for="address2" class="font-medium">Address 2</label>
                            <input id="address2" type="text" [(ngModel)]="address2" pInputText autocomplete="address-line2">
                        </div>
                    
                        <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                            <label for="town" class="font-medium">Town / City</label>
                            <input id="town" type="text" [(ngModel)]="town" pInputText autocomplete="address-line3">
                        </div>
                        <div *ngIf="!TownValid && !ProvideMinimalPersonalData" class="text-danger PersonalData"><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a town/city.</div>

                        <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                            <label for="postcode" class="font-medium">Post Code</label>
                            <input id="postcode" type="text" [(ngModel)]="postCode" pInputText autocomplete="postal-code">
                        </div>
                        <div *ngIf="!PostCodeValid && !ProvideMinimalPersonalData" class="text-danger PersonalData" ><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a postcode.</div>

                        <div class="field mb-4 col-12">
                            <label for="email" class="font-medium">Email</label>
                            <input id="email" type="email" [(ngModel)]="email" pInputText autocomplete="email">
                        </div>
                        <div *ngIf="!EmailValid" class="text-danger PersonalData"><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter an email address.</div>
                        <div *ngIf="!EmailFormatValid" class="text-danger PersonalData" ><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a valid email address.</div>
    
                        <div class="field mb-4 col-12">
                            <label for="mobile" class="font-medium">Mobile</label>
                            <input id="mobile" type="tel" pattern="^(\+44\s?|0)7\d{9}$" [(ngModel)]="mobile" pInputText autocomplete="tel">
                        </div>
                        <div *ngIf="!MobileValid" class="text-danger PersonalData"><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a mobile number.</div>
                        <div *ngIf="!MobileFormatValid" class="text-danger PersonalData" ><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>You must enter a valid mobile number.<div class="clearboth"></div></div>

                        <div class="field mb-4 col-12">
                            <p-selectButton id="useclasses" class="AgreeToTermsSmallButtons" [options]="stateOptionsTerms" [(ngModel)]="tandcsValid" ></p-selectButton>
                            <span class="AgreeToTerms"><label class="ShowTerms" for="agreeTC">Agree to</label>&nbsp;<span class="ShowAsHyperlink" (click)="showterms()">Terms & Conditions</span></span>
                            </div>
                            <div *ngIf="!tandcsValid && showtandcerror" class="text-danger PersonalData" ><span class="pi pi-exclamation-triangle" class="MarginRight5"></span>Your must agree to the Terms and Conditions before you can continue.</div>
                        
                            <div class="field mb-4 col-12">
                            <p-selectButton id="useclasses" class="AgreeToTermsSmallButtons" [options]="stateOptionsTerms" [(ngModel)]="emailMarketing" ></p-selectButton>
                            <span class="AgreeToTerms"><label class="ShowTerms" for="emailMarketing">Let us keep you updated with our latest news</label></span>
                        </div>

                        <div class="field mb-4 col-12">
                            <p-selectButton id="useclasses" class="AgreeToTermsSmallButtons" [options]="stateOptionsTerms" [(ngModel)]="smsMarketing" ></p-selectButton>
                            <span class="AgreeToTerms"><label class="ShowTerms" for="smsMarketing">Let us keep you updated by text</label></span>
                        </div>
                        
                        <div class="ClearWidth100Height20"></div>
                        <button pButton type="button" class="p-button p-button DoneCustomer" (click)="doneCustomer()">
                            <span>Next</span>
                            <span class="pi pi-chevron-right" class="MarginLeft8"></span>
                        </button>

                    </div>
                </div>


            </p-accordionTab>

            <div id="topOfPayments"></div>
            <p-accordionTab [(selected)]="paymentSelected" [disabled]="paymentActive">
                <p-header class="Width100">

                    <div class="Width100">
                        <span class="pi pi-money-bill" class="MarginRight10"></span> {{PaymentHeader}}
                    </div>
                </p-header>

                <div class="surface-card p-4 shadow-2 border-round mobileAccordianContent">
                    <div class="grid formgrid p-fluid">
                        <div *ngIf="totalValueOfBooking != 0" class="ValueOfBooking">
                            
                            <!--Total Value of Booking: {{totalValueOfBooking |  currency:'GBP'}} (Deposit: {{paymentAmount |  currency:'GBP'}})-->

                            Value of Booking: {{(NewBooking.totaladmissions) |  currency:'GBP'}} 
                            <span *ngIf="NewBooking.totaldiscounts > 0">(- Discount: {{NewBooking.totaldiscounts | currency:'GBP'}})</span>
                            <span *ngIf="NewBooking.totalbookingfees> 0"> + Booking Fee: {{NewBooking.totalbookingfees | currency:'GBP'}}</span>
                            <span *ngIf="NewBooking.totalextras> 0"> + Extras Value: {{NewBooking.totalextras | currency:'GBP'}}</span>
                            <span> (Total Value: {{(NewBooking.total) | currency:'GBP'}})</span>
                        </div>
 
                        <div class="ClearBothWidth100">
                    </div>


                    <div style="clear:both; width:100%"></div>
                    
                    <div *ngIf="paymentError != ''" style="color:red; font-weight:bold; width:100%">{{paymentError}}</div>

                    <form action="/charge" method="post" id="payment-form">
                        <div class="form-row">
                          <div id="payment-element">
                            
                          </div>
                          <div id="payment-errors" role="alert"></div>
                          <div id="payment-message" role="alert"></div>
                        </div>
                        <button class="p-button p-element p-button w-auto mt-3" style="width:100% !important;">Amount to pay now: {{NewBooking.totalpublicdeposit |  currency:'GBP'}}</button>
                      </form>
                      <div style="width:100%">
                        <div>
                            <button id="discountButton" style="margin-right:5px; margin-bottom:10px;" pButton type="button" class="p-button-secondary p-button-text" (click)="displayDiscount()">
                                <span class="pi pi-ticket" style="margin-right:8px;"></span>
                                <span>Add a discount code or a gift voucher.</span>
                            </button>
                        </div>

                        <div style="clear:both; width:100%"></div>

                        <div *ngIf="showDiscount" style="width:100%">
                            <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">
                                <div class="field mb-4 col-12" style="width:234px;float:left;">
                                    <input id="discountCode" placeholder="Discount Code" [(ngModel)]="discountCode" pInputText style="width:215px;">
                                </div>
                                <div style="float:left;">
                                    <button pButton type="button" style="height: 50px; margin-top:0px; width:82px;" class="p-button p-button" (click)="doneDiscount()">
                                        <span>Apply</span>
                                    </button>
                                </div>

                                <div style="clear:both; width:100%"></div>

                                <div class="field mb-4 col-12" style="float:left; width:234px;">
                                    <input id="voucherCode" placeholder="Gift Voucher" [(ngModel)]="voucherCode" pInputText style="float:left; width:135px;margin-right:10px">
                                    <input id="voucherCode" placeholder="PIN" [(ngModel)]="voucherpin" pInputText [maxlength]="4" type="password" style="float:left;width:70px;">
                                    <div style="clear:both; width:100%"></div>
                                </div>
                                <div style="float:left;">
                                    <button pButton type="button" style="height: 50px;margin-top:0px; width:82px;" class="p-button p-button" (click)="doneVoucher()">
                                        <span>Apply</span>
                                    </button>
                                </div>
                                <div style="clear:both; width:100%"></div>
                            </div>

                        </div>
                      </div>
                      <!-- <div *ngIf="PaymentInProgress">
                        <div>Making Payment, Please wait...</div>
                        <p-progressSpinner style="margin-left: 58px !important; height: 50px;"></p-progressSpinner>
                      </div> -->

                    </div>

                </div>
            </p-accordionTab>
        </p-accordion>
        </div>
        </div>
        <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
            Version:  {{version}}
            <br>
            <a href="https://wannabook.online">Powered by WannaBook</a>
            <div style="height:81px;">
              &nbsp;
          </div>
        </div>
    </div>
    <div id="topOfSummary"></div>
    <div *ngIf="BookingComplete  && !BookingTimeElasped">
        <div class="p-3 flex flex-column flex-auto" style="text-align:center;">
            <div *ngIf="this.BookingResponse.payments != null || noDepositBooking" style="text-align: center; margin-bottom:15px;">
                <img src="assets/images/tick.png" alt="tick" style="width: 100px; height: 100px;">
            </div>

            <div *ngIf="this.BookingResponse.payments == null && !noDepositBooking" style="text-align: center; margin-bottom:15px;">
                <img src="assets/images/timer.png" alt="timer" style="width: 100px; height: 100px;">
            </div>
            
            <h3 class="BookingSummaryHeader" i18n>{{BookingConfirmationSummary}}</h3>

            <div *ngIf="BookingSummary" style="text-align:left;">
                <!-- <div class="SummaryEntry">
                    <span class="pi pi-user" class="MarginRight10"></span><b>Customer</b> : {{BookingSummary.customerAddress}}
                </div> -->

                <div class="SummaryEntry" style="text-align:center;margin-bottom:15px;">
                    <span class="pi pi-calendar" class="MarginRight10"></span><b>Booking Ref: <br><span style="font-size:25px;">{{BookingSummary.bookingIdHR}}</span></b>
                </div>
                
                <div class="SummaryEntry">
                    <span class="pi pi-users" class="MarginRight10"></span><b>Admissions</b> : {{BookingSummary.admissions}}
                </div>
                <div class="SummaryEntry">
                    <span class="pi pi-calendar" class="MarginRight10"></span><b>Start Date/Time</b> : {{BookingSummary.startTime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}
                </div>
                <div class="SummaryEntry">
                    <span class="pi pi-box" class="MarginRight10"></span><b>Package</b> : {{BookingSummary.packageName}}
                </div>

    
                <div class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Sales Value</b> : {{BookingSummary.total | currency:'GBP' }}
                </div>
    
                <div *ngIf="BookingSummary.totaldiscounts > 0" class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Discounts</b> : {{BookingSummary.totaldiscounts | currency:'GBP' }}
                </div>
    
                <div *ngIf="BookingSummary.totalbookingfees > 0" class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Booking Fee</b> : {{BookingSummary.totalbookingfees | currency:'GBP' }}
                </div>
    
                <div *ngIf="BookingSummary.paid && BookingSummary.paid > 0" class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Paid</b> : <span>{{BookingSummary.paid | currency:'GBP' }}</span>
                </div>
                <div *ngIf="BookingSummary.paid <= 0" class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Paid</b> : {{BookingSummary.paid | currency:'GBP' }}
                </div>
    
                <div *ngIf="BookingSummary.outstanding && BookingSummary.outstanding > 0" class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Outstanding</b> : <span class="RedBold">{{BookingSummary.outstanding | currency:'GBP' }}</span>
                </div>
                <div *ngIf="BookingSummary.outstanding <= 0" class="SummaryEntry">
                    <span class="pi pi-pound" class="MarginRight10"></span><b>Outstanding</b> : {{BookingSummary.outstanding | currency:'GBP' }}
                </div>

                <div class="SummaryEntry">
                    <!-- <span class="pi pi-list" class="MarginRight10"></span><b>Itinerary</b>:
    
                    <div class="Itinerary">
                        <table [cellPadding]="3" class="MobileTable">
                             <tr class="InineraryEntries">
                                <td class="Width5">&nbsp;</td>
                                <td class="Width50">
                                    <b>Arrival:</b>
                                </td>
                                <td class="Width20">
                                    {{BookingSummary.arrival| date: 'HH:mm' : 'UTC'}}
                                </td >
                                <td class="Width20">&nbsp;</td>
                            </tr>
    
                            <tr class="InineraryEntries" *ngFor="let itin of (BookingSummary.itineraryEX)">
                                <td>&nbsp;</td>
                                <td>
                                   <b>{{itin.resource}}</b>
                                </td>
                                <td>
                                    {{itin.starttime}}
                                </td>
                                <td >
                                    {{itin.people}}&nbsp;people
                                </td>
    
                            </tr>
    
                            <tr class="InineraryEntries">
                                <td>&nbsp;</td>
                                <td>
                                    <b>End:</b>
                                </td>
                                <td>
                                    {{addMs(BookingSummary.endTime, 1)| date:'HH:mm' : 'UTC'}}
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>
                </div> -->
    
    
                <div *ngIf="BookingSummary.extras != ''" class="SummaryEntry">
                    <span class="pi pi-star" class="MarginRight10"></span><b>Extras</b> : {{BookingSummary.extras}}
                </div>
    
    
    
                <!-- <div class="SummaryEntry">
                    <span class="pi pi-envelope" class="MarginRight10"></span><b>Email</b> : {{BookingSummary.customerEmail}}
                </div>
                <div class="SummaryEntry">
                    <span class="pi pi-mobile" class="MarginRight10"></span><b>Mobile</b> : {{BookingSummary.customerMobile}}
                </div>
    
                <div class="SummaryEntry">
                    <span class="pi pi-file" class="MarginRight10"></span><b>Notes</b> : {{BookingSummary.notes}}
                </div> -->

                <div style="margin-top:0px;margin-bottom: 20px">{{BookingConfirmationText}}</div>

                <div *ngIf="returnPage != ''" class="StartAgain">
                    <button pButton pRipple type="button" class="p-button p-element p-button w-auto mt-3" class="Important100" (click)="startAgain()">
                    <span class="pi pi-calendar-plus" class="MarginRight8"></span>
                    Return to Website</button>
                </div>
            </div>
        </div>
    
        </div>

        <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
            Version: {{version}}
            <br>
            <a href="https://wannabook.online">Powered by WannaBook</a>
            <div style="height:81px;">
              &nbsp;
          </div>
        </div>
    </div>
</div>