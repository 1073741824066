<link rel="stylesheet" [href]="VenueCSS">

<div class="p-3 flex flex-column flex-auto" style="max-width: 500px;margin-left: auto; margin-right: auto;">
    <div class="padding15px">
        <div class="publicImage"><img src={{publicImageURL}} width={{imageWidth}} height={{imageHeight}}></div>
    </div>
    <div class="clearboth"></div>

  <div>

    <div style="margin-top: 20px; font-size: 18px; text-align: center; width: 100%" *ngIf="ListEvents == null || ListEvents.length == 0">There are no events running at the moment.</div>

    <div *ngFor="let e of ListEvents"  class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">
        <div style="width:100%">

            <div *ngIf="!checkDates(e.startdate,e.enddate)" style="float:left;font-size: 23px; padding-bottom:10px;">
                <b>{{e.name}}&nbsp;({{e.startdate | date:'dd/MM/yyyy' : 'UTC'}}&nbsp;-&nbsp;{{e.enddate | date:'dd/MM/yyyy' : 'UTC'}})</b>
            </div>

            <div *ngIf="checkDates(e.startdate,e.enddate)" style="float:left;font-size: 23px; padding-bottom:10px;">
                <b>{{e.name}}&nbsp;({{e.startdate | date:'dd/MM/yyyy' : 'UTC'}})</b>
            </div>


            <div *ngIf="e.imageurl != '' && e.imageurl != null" style="float:left;margin-bottom:0px;">
                <img src="{{e.imageurl}}" alt="{{e.name}}" width="100%" />
            </div>
            <div style="clear:both;height:0px;"></div>  
            <div *ngIf="IsDiscountValid(e)" style="width: 100%;background-color: #d30000;color: white;text-align: center;padding: 15px;font-weight: bold;">
                <span>
                    <img src="assets/images/littlebird.png" style="width: 50px;padding-right: 10px;">
                </span>
                <span>
                    You are entitled to an early bird discount
                </span>
            </div>

            <div class="CollapsibleDivs" id="dv{{e.id}}collapse" hidden="true">
                <div style="float:left;" [innerHTML]="e.description">
                </div>

                <div style="float:left; margin-top:20px;">
                    <div style="margin-bottom:20px; font-size: 18px;"><b>Select who's going:</b></div>

                    <div class="packageSelection clr" *ngFor="let ea of e.admissions">
                        <div style="margin-bottom:10px;" *ngIf="ea.mustinclude" class="PackageSelectLabel MarginBottom8">
                            {{ea.bookingwizardtext}}&nbsp;<b>{{ea.price | currency:'GBP'}}</b> *:
                        </div>
                        <div style="margin-bottom:10px;" *ngIf="!ea.mustinclude" class="PackageSelectLabel MarginBottom8">
                            {{ea.bookingwizardtext}}&nbsp;<b>{{ea.price | currency:'GBP'}}</b>:
                        </div>

                        <div style="margin-bottom:10px;text-align: center;" class="packageSelect" class="PackageSelect" >

                            <p-inputNumber [(ngModel)]="e.admissions[ea.index].noofpeople" (onInput)="updateAdm(e)" (onKeyDown)="updateAdm(e)" (change)="updateAdm(e)" [disabled]="ea.fixed"
                            [showButtons]="true" 
                            max="{{ea.max}}" 
                            min="{{ea.min}}"     
                            buttonLayout="horizontal" 
                            spinnerMode="horizontal" 
                            decrementButtonClass="p-button-secondary decrementButton"
                            incrementButtonClass="p-button-secondary incrementButton" 
                            incrementButtonIcon="pi pi-plus" 
                            decrementButtonIcon="pi pi-minus"></p-inputNumber>
                        </div>
                    </div>
                    <div *ngIf="!minNumberOfPeopleMet" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select at least {{MinAdmissions}} admissions.</div>

                <div>
                <div style="float:left;font-size: 18px;margin-top:20px;">
                    <span><b>Dates available:</b></span>
                    <div></div>
                    <div *ngFor="let ed of e.eventdates" style="float: left; margin-top:10px;">
                        <p-toggleButton style="margin-bottom:20px;" onLabel="{{ed.startdatetime | date:'dd/MM/yyyy':'UTC'}}" offLabel="{{ed.startdatetime | date:'dd/MM/yyyy':'UTC'}}" [(ngModel)]="selectedDateTime[ed.index]"  (onChange)="selectDate(ed, e)"></p-toggleButton>
                    </div>
                </div>

                <div style="width: 100%;height:20px;clear:both;">&nbsp;</div>
                <div *ngIf="doneAvailability">
                    <div>    
                        <div *ngFor="let st of (startTimesFiltered)" class="times">
                            <button class="p-button StartTimesFiltered" style="background-color: white; color: #495057;" [outlined]="true" (click)="bookSlot(st, e)">
                                <div style="width:100%; text-align: center;">
                                    <span class="pi pi-clock" style="margin-right:8px;"></span>
                                    <span>Select: {{ st.time | date:'HH:mm' : 'UTC'}}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    
                    <div class="ClearHeight75"></div>

                    <div *ngIf="startTimesFiltered === null || startTimesFiltered === undefined || startTimesFiltered.length == 0" class="NoSlotsAvailable">There are no times available that match your criteria.</div>
                </div>

                </div>
            
            
            
                </div>
            </div>
            <div style="width:100%;">
                <div>
                    <button style="margin-top:20px;width:100%" id="dv{{e.id}}toggleButton" class="p-button" (click)="toggleMore('dv'+e.id)">More Information</button>
                </div>
            </div>

        <div style="clear:both">&nbsp;</div>
    </div>
  </div>
  <div style="background-color: white;text-align: center;padding-top: 25px;font-size: 18px;font-weight: bold;border-top: dashed 1px #ccc;color: #ccc;">
    Version: {{version}}
    <br>
    <a href="https://www.wannabook.online">Powered by WannaBook</a>
    <div style="height:81px;">
      &nbsp;
  </div>
</div>
</div>
