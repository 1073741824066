import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BuyvoucherComponent } from './buyvoucher/buyvoucher.component';
import { BookingComponent } from './booking/booking.component';
import { TermsComponent } from './terms/terms.component';
import { PaymentComponent } from './payment/payment.component';
import { EventsListComponent } from './events-list/events-list.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'booking/:venue',
    component: BookingComponent,
  },
  {
    path: 'booking/:venue/:date',
    component: BookingComponent,
  },
  {
    path: 'terms/:venue',
    component: TermsComponent,
  },
  {
    path: 'events/:venue',
    component: EventsListComponent,
  },
  {
    path: 'payment',
    component: PaymentComponent,
  },
   {
     path: 'buyvoucher/:venue',
     component: BuyvoucherComponent
   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
