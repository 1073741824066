
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import { bmxurl } from 'src/globals';
import { cVenuesettings, venuesettings } from 'src/types/venuesettings';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
//import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from './auth-config';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
//import LogRocket from 'logrocket';
import { v4 as uuidv4  } from 'uuid';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  ShowTimeOut = false;
  httpResponse: any;
  loginDisplay = false;
  VenueID: string = "";
  VenueCSS: any;
  version: string = environment.version;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    //private gtmService: GoogleTagManagerService,
    private route: ActivatedRoute,
    keepalive: Keepalive,
    private idle: Idle,
    cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) 
  {
    // if (environment.production) {
    //   LogRocket.init('zslez2/wannabook');
    // }

    idle.setIdle(600); 
    idle.setTimeout(10); 
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); 

    idle.onIdleStart.subscribe(() => {
      this.ShowTimeOut = true;
      //idle.onIdleStart.unsubscribe();
    });


    idle.onIdleEnd.subscribe(() => {
      this.ShowTimeOut = false;
      this.countdown = null;
      cd.detectChanges(); 
      //idle.onIdleEnd.unsubscribe();
    });

    idle.onTimeout.subscribe(() => {
      this.ShowTimeOut = false;
      this.reset();
      localStorage.setItem("SmartUserID",null);
      idle.onTimeout.unsubscribe();
      //window.location.href=this.document.location.href;
      //this.router.navigateByUrl("/laserquestbromley");
      
    });

    idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    keepalive.interval(15); 
  }

  items: MenuItem[];
  GTMCode: string;

  reset() {
    this.idle.watch();
    this.ShowTimeOut = false;
    this.countdown = null;
    this.lastPing = null;
  }


  ngOnInit(): void {

    //get version
    if (environment.production) {
      this.http.get(bmxurl + "/version").subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded)
        {
          if(this.version != this.httpResponse.data.publicVersion)
          {
            alert("Versions don't match!");
            location.reload();  
          }
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number'});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number, error:' + error.messages[0]});
      });
    }

    this.reset();
    
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
}

}
